// custom change - new file
#conversejs {
    .chat-msg__content--carousel {
        max-width: 100% !important;
    }

    .chat-msg__body-billInfo, 
    .chat-msg__body-pc-info, 
    .chat-msg__body-thank-you-card, 
    .chat-msg__body-pa-info, 
    .chat-msg__body-OOBCharges, 
    .chat-msg__body-OOBChargesDetailed,
    .chat-msg__body-myPlan,
    .chat-msg__body-NPS {
        border: 1px solid var(--light-grey-border-color);
        background-color: var(--heading-color);
        padding: 20px;
        border-radius: 5px;
        width: 90vw;
        max-width: 300px;
        margin-top: 10px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
    }

    .chat-msg__body-billInfo--header {
        border-bottom: 1px solid var(--light-grey-border-color);
        padding-bottom: 16px;
    }

    .chat-msg__body-billInfo--header-grid {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .chat-msg__body-billInfo--services {
        border-bottom: 1px solid var(--light-grey-border-color);
        padding: 16px 0;
    }

    .chat-msg__body-billInfo--payment, 
    .chat-msg__body-OOBCharges--payment {
        padding-top: 16px;
    }

    .billInfo-header-title, 
    .billInfo-services-title {
        font-size: 16px;
        font-weight: bold;
    }

    .header-margin {
        margin-top: 5px;
    }

    .billInfo-header-month {
        font-size: 24px;
    }

    .billInfo-services-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 5px 0 5px;
        cursor: pointer;

        &:hover {
            opacity: 0.3;
        }
    }

    .billInfo-services-body-icons-mobile::before {
        font-size : 30px;
        color: var(--black-color);
    }

    .billInfo-header-navigation {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            opacity: 0.3;
        }
    }

    .billInfo-header-navigation-title {
        font-size: 18px;
    }

    .billInfo-header-navigation-link {
        margin-left: 10px;
        stroke: var(--link-color);
        stroke-width: 3px;

        &:hover {
            color: var(--link-color);
        }
    }

    .billInfo-payment-title, 
    .pc-info-title, 
    .thank-you-card-header-title {
        font-size: 21px;
        font-weight: bold;
        line-height: 1.5rem;
    }

    .billInfo-payment-phone-number {
        margin-top: 8px;
        font-size: 16px;
    }

    .billInfo-payment-subtitle {
        margin-top: 5px;
        font-size: 16px;
    }

    .billInfo-payment-amount {
        margin-top: 20px;
        font-size: 35px;
    }

    .billInfo-payment-button, 
    .pc-info-button, 
    .pa-info-button, 
    .OOBCharges-payment-button, 
    .OOBCharges-download-button,
    .tooltip-modal-button,
    .nps-submit-button {
        margin-top: 20px;
        width: 100%;
        background-color: var(--link-color);
        color: var(--heading-color);
        font-size: 16px;
        border-radius: 5px;
        border: none;

        &:hover {
            opacity: 0.3;
        }
    }

    .billInfo-payment-button:disabled, 
    .pc-info-button:disabled, 
    .pa-info-button:disabled, 
    .offer-button-container:disabled,
    .nps-submit-button:disabled {
        background-color: #ccc !important;
        cursor: default;

        &:hover {
            opacity: 1;
        }
    }

    .billInfo-services-body-navigation-anonymous, 
    .billInfo-payment-button-anonymous {
        text-decoration: none;
    }

    .pc-info-input, 
    .pa-info-input {
        max-width: 256px;
        width: 72vw;
        margin-top: 20px;
        height: 35px;
        padding-left: 10px;
        color: var(--black-color);
        text-align: center;
        border: 1px solid var(--black-color);
        align-self: center;
    }

    .pc-info-input-feedback, 
    .pa-info-input-feedback,
    .nps-input-feedback {
        color: var(--link-color) !important;
    }

    .pc-info-input-feedback--not-displayed, 
    .pa-info-input-feedback--not-displayed,
    .nps-input-feedback--not-displayed {
        display: none;
    }

    .pc-info-input-error, 
    .pa-info-input-error {
        border: 1px solid var(--link-color);
    }

    .pc-info-description, 
    .thank-you-card-description {
        margin-top: 15px;
        font-size: 16px;
    }

    .pa-info-description {
        font-size: 16px;
    }

    .chat-msg__body-thank-you-card-header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .thank-you-card-header-image {
        width: 50px;
        height: 50px;
    }

    // Offers carousel styles start
    .price-plan-container, 
    .oob-extended-carousel-container {
        width: 100vw;
        margin-bottom: 5px;
        position: relative;
    }
    .price-plan-wrapper, 
    .oob-extended-carousel-wrapper {
        overflow: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        gap: 15px;
        padding: 0 45px 0 45px;
    }
    .price-plan-wrapper::-webkit-scrollbar, 
    .oob-extended-carousel-wrapper::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
        background: transparent; /* make scrollbar transparent */
    }
    .d-flex {
        display: -ms-flexbox!important;
        display: flex!important;
    }
    .offer-container {
        flex: 0 0 285px;
    }
    .scroll-arrow {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 130px;
        background-color: rgb(255, 255, 255);
        height: 60px;
        width: 50px;
        border-radius: 34px 0px 0px 34px;
        box-shadow: rgba(0,0,0,0.32) 0px 4px 10px 0px;
        z-index: 2;
    
        &.left-arrow {
            border-radius: 0px 34px 34px 0px;
            right: unset;
            left: -18px;
        }
    
        .svg-icon {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .oob-extended-carousel-wrapper {
        .scroll-arrow {
            top: 100px;
        }
    }
    .scroll-svg {
        display: inline-flex;
        flex-direction: column;
        height: 1.5rem;
        width: auto;
        -webkit-box-align: center;
        align-items: center;
        color: inherit;
        pointer-events: none;
    
        svg {
            width: auto;
            height: 100%;
        }
    }
    .offer-price-int {
        font-family: "Vodafone Rg Bold", Arial, Helvetica, sans-serif;
        font-size: 28px;
        line-height: 1;
    }
    .offer-container .offer-price-wrapper .offer-price-int, 
    .offer-container .offer-price-wrapper .offer-price-dec {
        font-size: 34px;
    }
    .offer-price-dec {
        font-family: "Vodafone Rg Bold", Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1.5;
    }
    .offer-price-month {
        font-size: 12px;
        margin-left: 2px;
        margin-right: 8px;
        color: rgb(153, 153, 153);
    }
    .offer-container .offer-wrapper {
        margin: 1px 2px 3px;
        width: 100%;
        max-width: 280px;
    }
    .offer-wrapper, 
    .chat-msg__body-OOBExtendedDetailed {
        position: relative;
    }
    .offer-container .offer-wrapper .offer-card-content {
        min-height: 275px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .offer-card-content {
        background-color: var(--inverse-link-color);
        border-radius: 6px;
        box-shadow: rgba(0,0,0,0.13) 0px 1px 4px 1px;
        padding: 16px;
        z-index: 1;
        position: relative;
    }
    .offer-title {
        font-size: 22px;
        color: rgb(51, 51, 51);
        font-family: "Vodafone Lt", Arial, Helvetica, sans-serif;
        margin-bottom: 16px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        height: 35px;
    }
    .offer-price-container {
        display: flex;
        border-top: 1px solid rgb(235, 235, 235);
        border-bottom: 1px solid rgb(235, 235, 235);
        padding: 10px 0px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 16px;
    }
    .offer-validity {
        color: rgb(153, 153, 153);
        font-family: "Vodafone Rg", Arial, Helvetica, sans-serif;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 32px;
        white-space: normal;
    }
    .offer-link-wrapper {
        display: inline-flex;
    
        a {
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
        }
    }
    .offer-button-container.medium.chevron.right, 
    .offer-button-container.medium.chevron.down, 
    .offer-button-container.medium.chevron.up {
        padding-right: 55px;
    }
    .offer-button-container.chevron.right, 
    .offer-button-container.chevron.down, 
    .offer-button-container.chevron.up {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }
    .offer-button-container {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        font-family: "Vodafone Rg Bold", Arial, Helvetica, sans-serif;
        text-transform: none;
        border-radius: 6px;
        box-sizing: border-box;
        transition: all 0.3s ease-out 0s;
        color: rgb(51, 51, 51);
        font-weight: 700;
        line-height: 1.375;
        padding: 8px 16px;
        border: 1px solid transparent;
        font-size: 14px;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        margin: 0px;
    
        &.medium {
            padding: 10px 16px;
            line-height: 1.3;
        }
    
        &.chevron {
            position: relative;
            text-align: left;
        }
    
        &.secondary {
            background-color: rgb(51, 51, 51);
            color: var(--inverse-link-color);
        }
    }
    .offer-button-container.medium .chevron-right, 
    .offer-button-container.medium .chevron-down, 
    .offer-button-container.medium .chevron-up {
        right: 16px;
    }
    .offer-button-container.chevron .svg-icon {
        position: absolute;
    }
    .offer-link-wrapper .chevron-right {
        margin-left: 5px;
    }
    .offer-button-icon {
        display: inline-flex;
        flex-direction: column;
        height: 1em;
        width: auto;
        -webkit-box-align: center;
        align-items: center;
        color: inherit;
    
        svg {
            width: auto;
            height: 100%;
        }
    }
    .svg-icon svg {
        overflow: hidden;
        vertical-align: middle;
    }
    .offer-listing-wrapper {
        margin-top: 16px;
        flex: 1 0 auto;
    }
    .offer-listing-item {
        display: flex;
        flex: 1 1 auto;
        margin-bottom: 10px;
    
        .desc {
            font-size: 14px;
            font-family: "Vodafone Rg", Arial, Helvetica, sans-serif;
            padding-top: 4px;
            white-space: normal;
        }
    }
    .offer-listing-item > .svg-icon {
        display: block;
        width: 23px;
        height: 23px;
        margin-right: 8px;
    }
    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
        cursor: pointer;
    }
    // Offers carousel styles end

    // OOB styles start

    .chat-msg__body-OOBCharges, 
    .chat-msg__body-OOBChargesDetailed,
    .chat-msg__body-myPlan {
        border-radius: 25px;
    }

    .chat-msg__body-OOBCharges--header-details {
        margin-top: 6px;
    }

    .OOBCharges-header-month, 
    .OOBCharges-payment-title, 
    .OOBCharges-payment-total,
    .OOBExtendedDetailed-phone-number,
    .OOBExtendedDetailed-total {
        font-size: 16px;
    }

    .OOBCharges-payment-extra {
        text-align: right;
    }

    .OOBCharges-header-title {
        margin-top: 5px;
    }
    
    .OOBCharges-header-paid, 
    .OOBCharges-header-unpaid {
        padding: 6px 15px;
        border-radius: 15px;
        color: white;
        font-weight: bold;
    }

    .OOBCharges-header-paid {
        background-color: green;
    }

    .OOBCharges-header-unpaid {
        background-color: red;
    }

    .chat-msg__body-OOBCharges--payment-grid, 
    .chat-msg__body-OOBCharges--header-grid {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5px;
    }

    .OOBCharges-payment-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
 
    .chat-msg__body-OOBExtendedDetailed--header {
        margin-bottom: 10px;
    }

    .chat-msg__body-OOBChargesDetailed--item-grid, 
    .chat-msg__body-OOBExtendedDetailed--item-grid,
    .chat-msg__body-myPlan--item-grid {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }

    .chat-msg__body-OOBExtendedDetailed--item-grid > h3{
        margin-block-end: 0 !important;
    }

    .chat-msg__body-OOBExtendedDetailed--category-grid {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .chat-msg__body-OOBChargesDetailed--item-title, 
    .chat-msg__body-OOBChargesDetailed--item-value, 
    .chat-msg__body-OOBExtendedDetailed--item-title, 
    .chat-msg__body-OOBExtendedDetailed--item-value,
    .chat-msg__body-OOBChargesDetailed--header, 
    .chat-msg__body-OOBExtendedDetailed--header,
    .chat-msg__body-OOBExtendedDetailed--header-tooltip,
    .chat-msg__body-myPlan--item-title,
    .chat-msg__body-myPlan--item-value,
    .chat-msg__body-myPlan--header-text,
    .OOBCharges-header-title, 
    .OOBCharges-header-unpaid, 
    .OOBCharges-header-paid, 
    .OOBCharges-payment-extra {
        font-size: 15px;
    }

    .chat-msg__body-OOBChargesDetailed--item-value,
    .chat-msg__body-OOBExtendedDetailed--item-value {
        min-width: 85px;
        text-align: right;
    }
 
    .OOBCharges-header-month, 
    .OOBCharges-payment-title, 
    .OOBCharges-payment-total,
    .OOBExtendedDetailed-phone-number,
    .OOBExtendedDetailed-total,
    .chat-msg__body-OOBChargesDetailed--item-value,
    .chat-msg__body-OOBExtendedDetailed--item-value,
    .chat-msg__body-OOBExtendedDetailed--header,
    .chat-msg__body-OOBExtendedDetailed--header-tooltip,
    .chat-msg__body-OOBChargesDetailed--header > b,
    .chat-msg__body-myPlan--item-value > b,
    .chat-msg__body-myPlan--header > b {
        color: black;
        font-weight: bold;
    }

    .chat-msg__body-OOBChargesDetailed--item-title,
    .chat-msg__body-myPlan--item-title  {
        max-width: 170px;
    }

    .OOBChargesDetailed-line-delimiter,
    .OOBExtendedDetailed-line-delimiter,
    .myPlan-line-delimiter {
        border : 0.5px solid grey;
    }

    .chat-msg__body-OOBExtendedDetailed {
        flex: 0 0 300px;
    }
    .chat-msg__body-OOBExtendedDetailed .chat-msg__body-OOBExtendedDetailed-wrapper {
        background-color: var(--heading-color);
        padding: 20px;
        margin: 10px 0 0;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
    }

    .chat-msg__body-OOBExtendedDetailed-wrapper {
        border: 1px solid var(--light-grey-border-color);
        border-radius: 25px;
    }

    .chat-msg__body-OOBExtendedDetailed--category-tooltip {
        color: var(--link-color);

        &:hover {
            opacity: 0.3;
            cursor: pointer;
        }
    }

    .tooltip-modal {
        display: none;
        position: fixed;
        z-index: 4;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.2);
        padding: 10px 0;
        justify-content: center;
        align-items: center;
    }
    
    .tooltip-modal-content-container {
        display: flex;
        flex-direction: column;
        background-color: #fefefe;
        margin: 40vh auto 50vh auto;
        padding: 15px;
        max-width: 300px;
        border-radius: 10px 10px 10px 0;
        max-height: 60vh;
    }

    .tooltip-modal-content-text {
        overflow-y: auto;
        margin-top: 5px;
    }
    
    .tooltip-modal-close {
        align-self: flex-end;
        color: var(--link-color);
        float: right;
        font-weight: bold;

        &:hover,
        &:focus {
            text-decoration: none;
            cursor: pointer;
            opacity: 0.3;
        }
    }

    .tooltip-modal-button-container {
        display: flex;
        flex-direction: column;
    }

    .tooltip-modal-button {
        max-width: 150px;
    }

    .tooltip-modal-button-anonymous,
    .tooltip-modal-button {
        align-self: center;
    }

    .tooltip-modal-button {
        margin-top: 10px;
    }

    .tooltip-mva-button-clarification {
        font-size:13px;
        align-self: center;
    }

    .chat-msg__body-OOBExtendedDetailed--prorate-grid-tooltip {
        display: grid;
        gap: 10px;
        grid-template-columns: max-content;
        justify-content: normal;
        grid-auto-flow: column;
        border: 1px solid var(--link-color);
        border-radius: 10px;
        padding: 5px;
    }

    .chat-msg__body-OOBExtendedDetailed--prorate-grid-charges :first-child {
        margin-top: 0;
    }

    .chat-msg__body-OOBExtendedDetailed--prorate-grid-charges :last-child {
        margin-bottom: 0;
    }


    // OOB  styles end

    // My plan styles start

    .chat-msg__body-myPlan--header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .chat-msg__body-myPlan--item-value {
        display: flex;
        justify-content: flex-end;
        min-width: 80px;
    }

    .chat-msg__body-myPlan--item-grid {
        margin: 8px 0;
    }

    .chat-msg__body-myPlan--header-category {
        font-size: 16px;
    }

    // My plan styles end

    // NPS styles start
    .chat-msg__body-NPS-row{
        display: flex;
        justify-content: center;
        gap: 6px;
    }
      
    .chat-msg__body-NPS-row-item{
        visibility: hidden;
        height: 0;
        width: 0;

        &:checked + label, :hover + label{
            background-color: rgb(51, 51, 51);
            color: white;
        }
    }
      
    .chat-msg__body-NPS-row-item-label {
        display: flex;
        height:40px;
        width:40px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border : 1px solid rgb(51, 51, 51);
        border-radius: 50%;
    }

    .chat-msg__body-NPS-description {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .chat-msg__body-NPS-title {
        font-size: 16px;
        font-weight: bolder;
        margin-bottom: 10px;
    }
    // NPS styles end
    
    /* Media Query for Mobile Devices, for low resolution  Tablets, Ipads and for Tablets Ipads portrait mode */
    @media screen and (max-width: 767px) {
        .pa-info-input[type="date"]:not(:read-only)::before{
            content: attr(placeholder) !important;
            margin-right: 5px;
        }
        .pa-info-input[type="date"]:focus::before,
        .pa-info-input[type="date"]:valid::before  {
            content: "" !important;
        }   
    }

    @media screen and (max-width: 380px) {
        .offer-title {
            font-size : 18px;
        }
            
            
        .offer-container {
            flex: 0 0 240px;
        }

        .chat-msg__body-OOBExtendedDetailed {
            flex: 0 0 260px;
        }
            
        .offer-container .offer-wrapper {
            max-width: 235px;
        }

        .chat-msg__body-pc-info {
            .pc-info-title {
                font-size: 20px;
            }
        }

        .chat-msg__body-thank-you-card {
            .thank-you-card-header-title {
                font-size: 20px;
            }
        }
    }

    @media screen and (max-width: 365px) {
        .scroll-arrow {
            right: 10px;
        
            &.left-arrow {
                right: unset;
                left: -29px;
            }
        }

        .price-plan-wrapper, 
        .oob-extended-carousel-wrapper {
            padding: 0 45px 0 0;
        }
    }

    @media screen and (max-width: 350px) {
        .chat-msg__body-billInfo {
    
            .billInfo-header-navigation {
                margin-top:3px;
            }
    
            .billInfo-header-navigation-link {
                font-size: 27px;
            }

            .billInfo-payment-amount {
                font-size: 32px;
            }

            .billInfo-header-month, 
            .billInfo-payment-title {
                font-size: 20px;
            }
        }
        .chat-msg__body-pc-info {
            .pc-info-input {
                padding-left: 0;
            }
        }

        .thank-you-card-header-image {
            width: 40px;
            height: 40px;
        }

        .billInfo-payment-button, 
        .pc-info-button, 
        .pa-info-button, 
        .OOBCharges-payment-button, 
        .OOBCharges-download-button,
        .tooltip-modal-button,
        .nps-submit-button {
            font-size: 15px;
        }
    }

    @media screen and (max-width: 300px) {
        .chat-msg__body-OOBChargesDetailed--item-title, 
        .chat-msg__body-OOBChargesDetailed--item-value,
        .chat-msg__body-OOBExtendedDetailed--item-title, 
        .chat-msg__body-OOBExtendedDetailed--item-value,
        .chat-msg__body-OOBChargesDetailed--header, 
        .chat-msg__body-OOBExtendedDetailed--header,
        .chat-msg__body-OOBExtendedDetailed--header-tooltip,
        .chat-msg__body-myPlan--item-title,
        .chat-msg__body-myPlan--item-value,
        .chat-msg__body-myPlan--header-text,
        .OOBCharges-header-title, 
        .OOBCharges-header-unpaid, 
        .OOBCharges-header-paid, 
        .OOBCharges-payment-extra {
            font-size: 14px;
        }

        .OOBCharges-header-month, 
        .OOBCharges-payment-title, 
        .OOBCharges-payment-total,
        .OOBExtendedDetailed-phone-number,
        .OOBExtendedDetailed-total {
            font-size: 15px;
        }
    }
}